import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _Carousel2 from "./Carousel";
import _CarouselImage2 from "./CarouselImage";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Carousel", {
  enumerable: true,
  get: function () {
    return _Carousel.default;
  }
});
Object.defineProperty(exports, "CarouselImage", {
  enumerable: true,
  get: function () {
    return _CarouselImage.default;
  }
});

var _Carousel = _interopRequireDefault(_Carousel2);

var _CarouselImage = _interopRequireDefault(_CarouselImage2);

export default exports;
export const __esModule = exports.__esModule,
      Carousel = exports.Carousel,
      CarouselImage = exports.CarouselImage;